import React, { useState, useEffect, useRef, useContext } from "react";
import Loading from "./assets/loading8.gif";
import Logo from "./assets/DreyfusLogoSite.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerOcorrencias.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
import Hotkeys from 'react-hot-keys';
//import { saveAs } from "file-saver";
//import { Document, ImageRun, Packer, Paragraph } from "docx";
//import { functionalUpdate } from "react-table";
//import {writeFileSync} from "fs";
//import { writeFile } from 'fs/promises';
//import { Document, Packer, Paragraph, TextRun } from "docx";
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType 
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const cloneIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-clone'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}


const formatDataHora3 = function (cell, formatterParams, onRendered) {
  //cell - the cell component
  //formatterParams - parameters set for the column
  //onRendered - function to call when the formatter has been rendered
  //cl("clcell", cell.getValue());
  if (!cell.getValue()) { return }
  let dia = cell.getValue().substr(8, 2)
  let mes = cell.getValue().substr(5, 2)
  let ano = cell.getValue().substr(0, 4)
  let hora = cell.getValue().substr(11, 2)
  let minuto = cell.getValue().substr(14, 2)
  //let result = dia + '/' + mes + '/' + ano + ' ' + hora + 'h' + minuto
  let result = dia + '/' + mes + '/' + ano

  return result; //return the contents of the cell;  
}

function retmaisc(cell,field) {
  if (!cell) { return }
  let einput = document.getElementById(field);
  einput.value = einput.value.toUpperCase();
}
function padraoPasta(cell) {
  if (!cell) { return }
  let einputPasta = document.getElementById('inputPasta');
  if ((einputPasta.value.length <= 1)){return}
  if ((einputPasta.value.length >= 6)){
    document.getElementById("inputPagina").focus();
    return
  }
  let one = cell.substr(0, 1);
  var meses = ["JAN", "FEV", "MAR", "ABR", 
  "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", 
  "DEZ"];
  if(one >=0 && one <= 9) {
    //cl("number")
    let mesnumber = (parseInt(cell.substr(0, 2))-1)
    let mes = meses[mesnumber];
    let ano = 24;
    if ((cell.substr(3, 1)) === '/'){
      ano = cell.substr(3, 2)
    } else {
      ano = cell.substr(2, 2)
    }
    let result = mes + '/' + ano;
    einputPasta.value = result;
  //cl("clresult",result);    

  } else {
    //cl("letra")
    return
  }
  return

//cl("clparams",cell);  
}

function gerContratoLink(params) {

  window.location.href = "#/contratos-gerenciar";


}

function geraWordContr(ncontr) {
  const linkurl = "https://api.dreyfusvieira.com.br/docxcontr.php?apikey=398738497834758934759834758934&numcontr=" + ncontr;
  window.open(linkurl, '_blank');

}

async function geraRelw(buffer) {
  const linkurl = "https://api.dreyfusvieira.com.br/docxrelteste.php?apikey=398738497834758934759834758934";
//cl(linkurl);
  //cl(buffer);
  //window.open(linkurl, '_blank');
  var bodyFormData2 = new FormData();
  bodyFormData2.set('buffer', buffer);
  const response = await axios({
    method: 'post',
    url: linkurl,
    data: bodyFormData2,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  if (response) {
  //cl(response);

  };

}

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function removeOptions(selectElement) {
  var i, L = selectElement.options.length - 1;
  for (i = L; i >= 0; i--) {
    selectElement.remove(i);
  }
}

function removeOptions2(selectElement) {
  var i, L = selectElement.options.length - 2;
  for (i = L; i >= 0; i--) {
    selectElement.remove(i);
  }
}

function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCliout, setrepCliout] = useState([]);
  const [repCli, setrepCli] = useState([]);
  const [repTipoOcor, setrepTipoOcor] = useState([]);
  const [repTipoOcorList, setrepTipoOcorList] = useState([]);
  const [AddOcorrencia, setAddOcorrencia] = useState([]);
  const [AltOcorrencia, setAltOcorrencia] = useState([]);
  const [delOcorrencia, setdelOcorrencia] = useState([]);
  const [DocNow, setDocNow] = useState([]);
  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);
  const isFirstRun4 = useRef(true);
  const isFirstRunDados = useRef(true);
  const isFirstRunTipOcor = useRef(true);
  const isFirstRunDoc = useRef(true);


  useEffect(() => {


  }, [rep])

  useEffect(() => {



  }, [DocNow])

  useEffect(() => {


  }, [repTipoOcorList])

  useEffect(() => {
    var eleSelect = document.getElementById('selectAtivar');
    if(eleSelect){removeOptions2(document.getElementById('selectAtivar'));}

    for (var i = 0; i < repCliout.length; i++) {

      let opt = document.createElement('option');
      opt.value = repCliout[i].nome + ' ││ ' + repCliout[i].tipo + ' ││ Contrato: Nº' + repCliout[i].numcontr;
      opt.innerHTML = repCliout[i].nome + ' ││ ' + repCliout[i].tipo + ' ││ Contrato: Nº' + repCliout[i].numcontr;
      eleSelect.appendChild(opt);
    }


  }, [repCliout])

  useEffect(() => {
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.dreyfusvieira.com.br/listaContratosAtivos.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      //let eleLoading = document.getElementById('divLoad');
      //eleLoading.style.display = 'none';
      if (!ignore) {
        const redataCli = response['data'];
      //cl(redataCli);
        setrepCliout(redataCli);


        //eleSelect.getAttribute('size').value = 10; 

        setrepTipoOcor({ '0': '0' });
      }
    }
    fetchdata();


    return () => { ignore = true };
  }, [repCli])

  useEffect(() => {
    if (isFirstRunTipOcor.current) {
      isFirstRunTipOcor.current = false;
      return;
    }
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.dreyfusvieira.com.br/listaTiposOcorrencias.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      //let eleLoading = document.getElementById('divLoad');
      //eleLoading.style.display = 'none';
      if (!ignore) {
        const redataTipoOcor = response['data'];
        //cl('redataTipoOcor', redataTipoOcor);

        setrepTipoOcorList(redataTipoOcor);
      }
    }
    fetchdata();


    return () => { ignore = true };
  }, [repTipoOcor])

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      return;
    }
    let ignore = false;
    const { numcontr, ocorrencia, pasta, pagina, emitente, desc, nfe_data, nfe_num, nfe_vlr, nfe_vlr_pg, dif, cidade, pcc } = AddOcorrencia;
    if (!ocorrencia){return};
  //cl("AddOcorrencia", AddOcorrencia)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('numcontr', numcontr);
      bodyFormData.set('ocorrencia', ocorrencia);
      bodyFormData.set('pasta', pasta);
      bodyFormData.set('pagina', pagina);
      bodyFormData.set('emitente', emitente);
      bodyFormData.set('desc', desc);
      bodyFormData.set('nfe_data', nfe_data);
      bodyFormData.set('nfe_num', nfe_num);
      bodyFormData.set('nfe_vlr', nfe_vlr);
      bodyFormData.set('nfe_vlr_pg', nfe_vlr_pg);
      bodyFormData.set('dif', dif);
      bodyFormData.set('cidade', cidade);
      bodyFormData.set('pcc', pcc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: 'https://api.dreyfusvieira.com.br/newocorrencia.php',

        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { cl("reponse", response) };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setAtu({ '0': '0' });
        //cl('setatu 0 0')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddOcorrencia])

  useEffect(() => {
    if (isFirstRun3.current) {
      isFirstRun3.current = false;
      return;
    }
    let ignore = false;
    const { id, ocorrencia, pasta, pagina, emitente, desc, nfe_data, nfe_num, nfe_vlr, nfe_vlr_pg, dif, cidade, pcc } = AltOcorrencia;
    if (!ocorrencia){return};
    
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('id', id);
      bodyFormData.set('ocorrencia', ocorrencia);
      bodyFormData.set('pasta', pasta);
      bodyFormData.set('pagina', pagina);
      bodyFormData.set('emitente', emitente);
      bodyFormData.set('desc', desc);
      bodyFormData.set('nfe_data', nfe_data);
      bodyFormData.set('nfe_num', nfe_num);
      bodyFormData.set('nfe_vlr', nfe_vlr);
      bodyFormData.set('nfe_vlr_pg', nfe_vlr_pg);
      bodyFormData.set('dif', dif);
      bodyFormData.set('cidade', cidade);
      bodyFormData.set('pcc', pcc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: 'https://api.dreyfusvieira.com.br/altocorrencia.php',

        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { cl("reponse", response) };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setAtu({ '0': '0' });
        //cl('setatu 0 0')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AltOcorrencia])

  useEffect(() => {
    if (isFirstRun4.current) {
      isFirstRun4.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    const { id } = delOcorrencia;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('id', id);
      bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',
        url: 'https://api.dreyfusvieira.com.br/delocor.php?apikey=398738497834758934759834758934',

        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };

      if (!ignore) {
        setAtu({ '01': '01' });
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [delOcorrencia])


  useEffect(() => {
    if (isFirstRunDados.current) {
      isFirstRunDados.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
    //let eleLoading = document.getElementById('divLoad');
    //if (eleLoading.style.display == 'none') {
    //  eleLoading.style.display = '';
    //}
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '$#T#@!M%T@$MG@$OJG48ugyf987nszovhunfc4');
      bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.dreyfusvieira.com.br/listaOcorrencias.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
      //cl("clredata2",redata2);
        global.gUltimaPasta = redata2[0].pasta;
      //cl("global.gUltimaPasta",global.gUltimaPasta)
        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        eleLoading.style.display = 'none';
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  const ref = null;

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, hozAlign: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: true, responsive: 0, headerSort: true },
    //{
    //  title: 'Data', field: 'createdAt', hozAlign: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //    outputFormat: "DD/MM/YY",
    //    invalidPlaceholder: "(invalid date)",
    //  }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    //},
    // // { title: 'Data', field: 'createdAt' ,hozAlign: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Ocorrência', field: 'ocorrencia', hozAlign: "left", width: 125, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Pasta', field: 'pasta', hozAlign: "center", width: 70, responsive: 0 },
    { title: 'Pagina', field: 'pagina', hozAlign: "center", width: 70, responsive: 0, },
    { title: 'Emitente', field: 'emitente', hozAlign: "left", width: 120, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Descrição', field: 'desc', hozAlign: "left", width: 120, responsive: 0, },
    //{ title: 'Sindico', field: 'sindico', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'NF Nº', field: 'nfe_num', hozAlign: "center", width: 70, responsive: 0, headerSort: false },
    {
      title: 'NF Data', field: 'nfe_data', resizable: false, hozAlign: 'center', width: 88, responsive: 0, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true, formatter: formatDataHora3
    },

/*     {
      title: 'NF Data', field: 'nfe_data', hozAlign: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    }, */
    { title: 'NF Valor', field: 'nfe_vlr', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'vlr Pago', field: 'nfe_vlr_pg', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Vlr Dif.', field: 'dif', hozAlign: "right", width: 70, responsive: 0, headerSort: false },
    { title: 'Cidade', field: 'cidade', hozAlign: "left", width: 100, responsive: 0, },
    { title: 'PCC', field: 'pcc', hozAlign: "right", width: 60, responsive: 0, headerSort: false },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editarOcorrência(cell.getRow().getData().id,cell.getRow().getData().ocorrencia, cell.getRow().getData().pasta, cell.getRow().getData().pagina, cell.getRow().getData().emitente, cell.getRow().getData().desc, cell.getRow().getData().nfe_num, cell.getRow().getData().nfe_data, cell.getRow().getData().nfe_vlr, cell.getRow().getData().nfe_vlr_pg, cell.getRow().getData().dif, cell.getRow().getData().cidade, cell.getRow().getData().pcc) } },
    { title: 'Copiar', formatter: cloneIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { cloneOcorrência(cell.getRow().getData().id,cell.getRow().getData().ocorrencia, cell.getRow().getData().pasta, cell.getRow().getData().pagina, cell.getRow().getData().emitente, cell.getRow().getData().desc, cell.getRow().getData().nfe_num, cell.getRow().getData().nfe_data, cell.getRow().getData().nfe_vlr, cell.getRow().getData().nfe_vlr_pg, cell.getRow().getData().dif, cell.getRow().getData().cidade, cell.getRow().getData().pcc) } },
    { title: 'Deletar', formatter: iconLixeira, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelOcor(cell.getRow().getData().id, cell.getRow().getData().ocorrencia, cell.getRow().getData().pasta, cell.getRow().getData().pagina) } },
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', hozAlign: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', hozAlign: 'center', width: 100, responsive: 0 },
    // { title: 'Origem', field: 'origem', hozAlign: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, hozAlign: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', hozAlign: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, hozAlign: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', formatter: emailIcon, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { iconSendMail(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', hozAlign: 'center' },
  ];

  const columnsProp = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, hozAlign: "center", resizable: false, responsive: 0 },
    //{ title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    // {
    //  title: 'Data', field: 'createdAt', hozAlign: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //   outputFormat: "DD/MM/YY",
    //   invalidPlaceholder: "(invalid date)",
    // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    // },
    // { title: 'Data', field: 'createdAt' ,hozAlign: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    { title: 'Chave', field: 'chave', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Tipo', field: 'tipo', hozAlign: "center", width: 200, responsive: 0, },
    { title: 'Numero', field: 'prop', hozAlign: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Valor Total', field: 'vlr_total', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Parcelas', field: 'parc', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Valor Parcelas', field: 'vlr_parc', hozAlign: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Prazo', field: 'qtdDias', hozAlign: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
    //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
    //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    //{ title: 'Cidade/Estado', field: 'cidstate', hozAlign: 'center', width: 120, responsive: 0 },
    //{ title: 'Fone', field: 'fone', hozAlign: 'center', width: 130, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Origem', field: 'origem', hozAlign: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', hozAlign: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', hozAlign: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, hozAlign: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    // { title: 'Custom', field: 'custom', hozAlign: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', hozAlign: 'center' },
  ];

  // const data = [
  //   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
  //   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
  //   { id: 3, name: 'Christine Lobowski', age: '42', color: 'green', dob: '10/05/1985', rating: 4, passed: false },
  //   { id: 4, name: 'Brendon Philips', age: '125', color: 'red', dob: '01/08/1980', rating: 4.5, passed: true },
  //   { id: 5, name: 'Margret Marmajuke', age: '16', color: 'yellow', dob: '07/01/1999', rating: 4, passed: false },
  //   {
  //     id: 6,
  //     name: 'Van Ng',
  //     age: '37',
  //     color: 'green',
  //     dob: '06/10/1982',
  //     rating: 4,
  //     passed: true,
  //     pets: ['dog', 'fish']
  //   },
  //   { id: 7, name: 'Duc Ng', age: '37', color: 'yellow', dob: '10/10/1982', rating: 4, passed: true, pets: ['dog'] }
  // ];

  // // Editable Example:
  // const colorOptions = { '': '&nbsp;', red: 'red', green: 'green', yellow: 'yellow' };
  // const petOptions = [{ id: 'cat', name: 'cat' }, { id: 'dog', name: 'dog' }, { id: 'fish', name: 'fish' }];
  const options = {
    height: 215,
    // movableRows: true,
    clipboard: true,
    invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "createdAt", dir: "desc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };
  function cloneOcorrência(id,ocorrencia,pasta,pagina,emitente,desc,nfe_num,nfe_data,nfe_vlr,nfe_vlr_pg,dif,cidade,pcc) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    gGlobal.gOcorrAltId = id;
  
    var eleSelect2 = document.getElementById('selectTipoOcor');
    eleSelect2.innerHTML = '';
  
    let opt2 = document.createElement('option');
    opt2.value = "Selecione";
    opt2.innerHTML = "Selecione";
    eleSelect2.appendChild(opt2);
  
    for (var i = 0; i < repTipoOcorList.length; i++) {
      if (repTipoOcorList[i].auditoria === gGlobal.gTipoOcor) {
        let opt3 = document.createElement('option');
        //cl(repTipoOcorList[i].auditoria, gGlobal.gTipoOcor);
        opt3.value = repTipoOcorList[i].nome;
        opt3.innerHTML = repTipoOcorList[i].auditoria + " - " + repTipoOcorList[i].nome;
        eleSelect2.appendChild(opt3);
      }
    }  
    document.getElementById('btnGravar').style.display = '';
    document.getElementById('btnAlterar').style.display = 'none';
    //let eleCard = document.getElementById('cardGridContr');
    //eleCard.classList.add("collapsed-card");
    let eselectTipoOcor = document.getElementById('selectTipoOcor');
    if(eselectTipoOcor){eselectTipoOcor.value = ocorrencia;}
    setTipoOcorInput();
    document.getElementById('inputPasta').value = '';
    document.getElementById('inputPagina').value = '';
    document.getElementById('inputEmitente').value = '';
    document.getElementById('inputDesc').value = '';
    document.getElementById('inputNf_num').value = '';
    document.getElementById('inputNf_data').value = '';
    document.getElementById('inputNf_valor').value = '';
    document.getElementById('inputVlr_pago').value = '';
    document.getElementById('inputDif').value = '';
    document.getElementById('inputCidade').value = '';
    document.getElementById('inputPcc').value = '';
    //cl("ocorrencia",ocorrencia)
    let ele2 = document.getElementById('tituloContr');
    ele2.textContent = "Lançar nova ocorrência...";
    if(pasta){document.getElementById('inputPasta').value = pasta;}
    //if(pagina){document.getElementById('inputPagina').value = pagina;}
    if(emitente){document.getElementById('inputEmitente').value = emitente;}
    if(desc){document.getElementById('inputDesc').value = desc;}
    //if(nfe_num){document.getElementById('inputNf_num').value = nfe_num;}
    //if(nfe_data){document.getElementById('inputNf_data').value = nfe_data;}
    //if(nfe_vlr){document.getElementById('inputNf_valor').value = nfe_vlr;}
    //if(nfe_vlr_pg){document.getElementById('inputVlr_pago').value = nfe_vlr_pg;}
    //if(dif){document.getElementById('inputDif').value = dif;}
    //if(cidade){document.getElementById('inputCidade').value = cidade;}
    //if(pcc){document.getElementById('inputPcc').value = pcc;}
    document.getElementById('inputPasta').focus()  ;
  
  }

  function editarOcorrência(id,ocorrencia,pasta,pagina,emitente,desc,nfe_num,nfe_data,nfe_vlr,nfe_vlr_pg,dif,cidade,pcc) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    gGlobal.gOcorrAltId = id;
  
    var eleSelect2 = document.getElementById('selectTipoOcor');
    eleSelect2.innerHTML = '';
  
    let opt2 = document.createElement('option');
    opt2.value = "Selecione";
    opt2.innerHTML = "Selecione";
    eleSelect2.appendChild(opt2);
  
    for (var i = 0; i < repTipoOcorList.length; i++) {
      if (repTipoOcorList[i].auditoria === gGlobal.gTipoOcor) {
        let opt3 = document.createElement('option');
        //cl(repTipoOcorList[i].auditoria, gGlobal.gTipoOcor);
        opt3.value = repTipoOcorList[i].nome;
        opt3.innerHTML = repTipoOcorList[i].auditoria + " - " + repTipoOcorList[i].nome;
        eleSelect2.appendChild(opt3);
      }
    }  
    document.getElementById('btnGravar').style.display = 'none';
    document.getElementById('btnAlterar').style.display = '';
    //let eleCard = document.getElementById('cardGridContr');
    //eleCard.classList.add("collapsed-card");
    let eselectTipoOcor = document.getElementById('selectTipoOcor');
    if(eselectTipoOcor){eselectTipoOcor.value = ocorrencia;}
    setTipoOcorInput();
    document.getElementById('inputPasta').value = '';
    document.getElementById('inputPagina').value = '';
    document.getElementById('inputEmitente').value = '';
    document.getElementById('inputDesc').value = '';
    document.getElementById('inputNf_num').value = '';
    document.getElementById('inputNf_data').value = '';
    document.getElementById('inputNf_valor').value = '';
    document.getElementById('inputVlr_pago').value = '';
    document.getElementById('inputDif').value = '';
    document.getElementById('inputCidade').value = '';
    document.getElementById('inputPcc').value = '';
    //cl("ocorrencia",ocorrencia)
    let ele2 = document.getElementById('tituloContr');
    ele2.textContent = "Editar Ocorrência (" +id +") - "+ ocorrencia + " Pasta: " + pasta;
    if(pasta){document.getElementById('inputPasta').value = pasta;}
    if(pagina){document.getElementById('inputPagina').value = pagina;}
    if(emitente){document.getElementById('inputEmitente').value = emitente;}
    if(desc){document.getElementById('inputDesc').value = desc;}
    if(nfe_num){document.getElementById('inputNf_num').value = nfe_num;}
    if(nfe_data){document.getElementById('inputNf_data').value = nfe_data;}
    if(nfe_vlr){document.getElementById('inputNf_valor').value = nfe_vlr;}
    if(nfe_vlr_pg){document.getElementById('inputVlr_pago').value = nfe_vlr_pg;}
    if(dif){document.getElementById('inputDif').value = dif;}
    if(cidade){document.getElementById('inputCidade').value = cidade;}
    if(pcc){document.getElementById('inputPcc').value = pcc;}
  
  }

  function relOcorrencia(params) {
    geraWordOcorrencias();

    

  }
  function novaOcorrencia(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';
    let ele2 = document.getElementById('tituloContr');
    ele2.textContent = "Lançar nova ocorrência...";

    document.getElementById('btnGravar').style.display = '';
    document.getElementById('btnAlterar').style.display = 'none';

    document.getElementById('inputPasta').value = global.gUltimaPasta;
    document.getElementById('inputPagina').value = '';
    document.getElementById('inputEmitente').value = '';
    document.getElementById('inputDesc').value = '';
    document.getElementById('inputNf_num').value = '';
    document.getElementById('inputNf_data').value = '';
    document.getElementById('inputNf_valor').value = '';
    document.getElementById('inputVlr_pago').value = '';
    document.getElementById('inputDif').value = '';
    document.getElementById('inputCidade').value = '';
    document.getElementById('inputPcc').value = '';

    //cl('repTipoOcor', repTipoOcorList);
    var eleSelect2 = document.getElementById('selectTipoOcor');
    eleSelect2.innerHTML = '';

    //opt2.value = 'Selecione';
    //opt2.innerHTML = 'Selecione';
    //eleSelect2.appendChild(opt2);
    let opt2 = document.createElement('option');
    opt2.value = "Selecione";
    opt2.innerHTML = "Selecione";
    eleSelect2.appendChild(opt2);

    for (var i = 0; i < repTipoOcorList.length; i++) {
      if (repTipoOcorList[i].auditoria === gGlobal.gTipoOcor) {
        let opt3 = document.createElement('option');
        //cl(repTipoOcorList[i].auditoria, gGlobal.gTipoOcor);
        opt3.value = repTipoOcorList[i].nome;
        opt3.innerHTML = repTipoOcorList[i].auditoria + " - " + repTipoOcorList[i].nome;
        eleSelect2.appendChild(opt3);
      }
    }

    setTipoOcorInput();
    /*     scrollTo(window, { top: 500 }).then(function () {
          // window has scrolled 500 pixels down the page
        }); */
  }



  function setTipoOcorInput(params) {
    var resultTipoOco = document.getElementById("selectTipoOcor");
    var iPasta = document.getElementById("inputPasta");
    var iPagina = document.getElementById("inputPagina");
    var iEmitente = document.getElementById("inputEmitente");
    var iDesc = document.getElementById("inputDesc");
    var iNf_num = document.getElementById("inputNf_num");
    var iNf_data = document.getElementById("inputNf_data");
    var iNf_valor = document.getElementById("inputNf_valor");
    var iVlr_pago = document.getElementById("inputVlr_pago");
    var iDif = document.getElementById("inputDif");
    var iCidade = document.getElementById("inputCidade");
    var iPcc = document.getElementById("inputPcc");
    //cl('selectTipoOcor', resultTipoOco.value);
    for (var i = 0; i < repTipoOcorList.length; i++) {
      if ((repTipoOcorList[i].auditoria === gGlobal.gTipoOcor) && (repTipoOcorList[i].nome === resultTipoOco.value)) {

        if (repTipoOcorList[i].pasta === 'X') {
          if (iPasta.disabled) { iPasta.disabled = false };
        } else {
          if (!iPasta.disabled) { iPasta.disabled = true };
        }

        if (repTipoOcorList[i].pagina === 'X') {
          if (iPagina.disabled) { iPagina.disabled = false };
        } else {
          if (!iPagina.disabled) { iPagina.disabled = true };
        }

        if (repTipoOcorList[i].emitente === 'X') {
          if (iEmitente.disabled) { iEmitente.disabled = false };
        } else {
          if (!iEmitente.disabled) { iEmitente.disabled = true };
        }

        if (repTipoOcorList[i].desc === 'X') {
          if (iDesc.disabled) { iDesc.disabled = false };
        } else {
          if (!iDesc.disabled) { iDesc.disabled = true };
        }

        if (repTipoOcorList[i].nfe_num === 'X') {
          if (iNf_num.disabled) { iNf_num.disabled = false };
        } else {
          if (!iNf_num.disabled) { iNf_num.disabled = true };
        }

        if (repTipoOcorList[i].nfe_data === 'X') {
          if (iNf_data.disabled) { iNf_data.disabled = false };
        } else {
          if (!iNf_data.disabled) { iNf_data.disabled = true };
        }

        if (repTipoOcorList[i].nfe_vlr === 'X') {
          if (iNf_valor.disabled) { iNf_valor.disabled = false };
        } else {
          if (!iNf_valor.disabled) { iNf_valor.disabled = true };
        }

        if (repTipoOcorList[i].nfe_vlr_pg === 'X') {
          if (iVlr_pago.disabled) { iVlr_pago.disabled = false };
        } else {
          if (!iVlr_pago.disabled) { iVlr_pago.disabled = true };
        }

        if (repTipoOcorList[i].dif === 'X') {
          if (iDif.disabled) { iDif.disabled = false };
        } else {
          if (!iDif.disabled) { iDif.disabled = true };
        }

        if (repTipoOcorList[i].cidade === 'X') {
          if (iCidade.disabled) { iCidade.disabled = false };
        } else {
          if (!iCidade.disabled) { iCidade.disabled = true };
        }

        if (repTipoOcorList[i].pcc === 'X') {
          if (iPcc.disabled) { iPcc.disabled = false };
        } else {
          if (!iPcc.disabled) { iPcc.disabled = true };
        }

      }
    }
    document.getElementById("inputPasta").focus();

  }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function CalcSimule(params) {

    var iNf_valor = document.getElementById("inputNf_valor");
    let V_Nf_valor = realParaNumber(iNf_valor.value);
    if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iVlr_pago = document.getElementById("inputVlr_pago");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iDif = document.getElementById("inputDif");
    let V_dif = realParaNumber(iDif.value);
    if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iPcc = document.getElementById("inputPcc");
    let V_Pcc = realParaNumber(iPcc.value);
    if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
      V_dif = V_pago - V_Nf_valor;
      if (V_dif > 0) {
        iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      }
    }

    if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
      V_Pcc = V_Nf_valor * 0.045;
      if (V_Pcc > 0) {
        iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      }
    }




  }

  function confirmDelOcor(id, ocor, pasta, pag) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar esta Ocorrência ?</h1><br></br><br></br>
            <p id='pmodal'><b>{id} - {ocor} - {pasta} - {pag}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                                 setdelOcorrencia(
                                  {
                                    'id': id
                                  });
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }
  
  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function rel1(params) {

    const table = new Table({
      columnWidths: [3505, 5505],
      rows: [
          new TableRow({
              children: [
                  new TableCell({
                      width: {
                          size: 3505,
                          type: WidthType.DXA,
                      },
                      children: [new Paragraph("Hello")],
                  }),
                  new TableCell({
                      width: {
                          size: 5505,
                          type: WidthType.DXA,
                      },
                      children: [],
                  }),
              ],
          }),
          new TableRow({
              children: [
                  new TableCell({
                      width: {
                          size: 3505,
                          type: WidthType.DXA,
                      },
                      children: [],
                  }),
                  new TableCell({
                      width: {
                          size: 5505,
                          type: WidthType.DXA,
                      },
                      children: [new Paragraph("World")],
                  }),
              ],
          }),
      ],
  });
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: "Hello World",
              heading: HeadingLevel.HEADING_1,
            }),
            table,
            new Paragraph({
              text: "Hello World",
              heading: HeadingLevel.HEADING_1,
            }),
          ],
        },
      ],
    });

    geraRelw(doc);
/*     Packer.toBuffer(doc).then((buffer) => {
    //cl("buffer1",buffer);
    //cl("doc",doc);
    geraRelw(buffer);
    
      //fs.writeFileSync("My Document.docx", buffer);

    }); */

  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardOcorrencias" style={{ display: 'none' }}>
          <div className="card-header ui-sortable-handle gertitle locCont" >
            <h3 className="card-title locCont">Ocorrências Lançadas</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+o"
                onKeyDown={novaOcorrencia}
              //onKeyUp={novoContato}
              />
              <button type="button" className="btn bg-gradient-info btn-sm " onClick={novaOcorrencia} style={{ margin: '-3px 20px' }}>Nova Ocorrência &#40;Shift+O&#41;</button>
              <button type="button" className="btn bg-gradient-info btn-sm " onClick={relOcorrencia} style={{ margin: '-3px 20px' }}>Relatório</button>
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBody">
            <ReactTabulator
              ref={(ref)}
              columns={columns}
              data={rep}
              rowClick={rowClick}
              cellClick={rowClick}
              options={options}
              data-custom-attr="test-custom-attribute"
              className="#example-table-theme"
            />
            {/* <div className="divLineBar">
            <img id="imgLineBar" width="auto10%" alt="Loading.." src={LineBar}></img>
            </div> */}
            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }

  function pageAtivar(params) {
    return (
      <div id="panelAtivar" name="panelAtivar" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-warning cardAddBgg" id="panelAtiva">
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContrAtivo">Ative o Cliente na Lista Abaixo</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" id="ipanelAtiva" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardpanelAtiva">
                  {/* Conversations are loaded here */}
                  <form id="formAtivarCliente">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label >Selecione abaixo</label>
                        <select className="form-control" id="selectAtivar" placeholder="Selecione abaixo o cliente que irá lançar as Ocorrências" >
                          <option value='0'>Selecione</option>
                        </select>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnAtivar" onClick={e => AtivarCliente(document.getElementById('selectAtivar').value)}>Ativar</button>
                  </form>




                </div>

                <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Lançar nova ocorrência...</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label htmlFor="inputNomeS">Tipo de Ocorrência</label>
                        <select className="form-control" id="selectTipoOcor" placeholder="Selecione abaixo o tipo de ocrrência que irá lançar" onChange={e => setTipoOcorInput()} >
                          <option value='0'>Selecione</option>
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="inputRG">Pasta (MM/AA)</label>
                        <input type="text" className="form-control" placeholder="ex.: 01/23" onChange={e => padraoPasta(e.target.value, 'inputEmail6')} id="inputPasta" disabled />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="inputCPF">Pagina</label>
                        <input type="text" className="form-control" placeholder="" id="inputPagina" onChange={e => retmaisc(e.target.value, 'inputPagina')} disabled />
                      </div>
                      {/*                       <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >Eleito em </label>
                          <input type="date" className="form-control" id="inputDtEleito" placeholder="eleio em" />
                        </div>

                      </div> */}
                    </div>
                    <div className="form-row">

                      <div className="form-group col-md-6">
                        <label htmlFor="inputProf">Fornecedor / Prestador</label>
                        <input type="text" className="form-control" placeholder="" id="inputEmitente" onChange={e => retmaisc(e.target.value, 'inputEmitente')} disabled />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputEstadoCivil">Produto / Serviço</label>
                        <input type="text" className="form-control" placeholder="" id="inputDesc" onChange={e => retmaisc(e.target.value, 'inputDesc')} disabled />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label >Nota Fiscal - Nº</label>
                        <input type="text" className="form-control" id="inputNf_num" placeholder="" disabled />
                      </div>
                      <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >Nota Fiscal - Data</label>
                          <input type="date" className="form-control" id="inputNf_data" placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Nota Fiscal - Valor</label>
                          <input type="text" className="form-control"  id="inputNf_valor" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Valor Pago</label>
                          <input type="text" className="form-control" id="inputVlr_pago" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Diferença</label>
                          <input type="text" className="form-control" id="inputDif" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-9">
                        <label >Cidade</label>
                        <input type="text" className="form-control" onChange={e => validaCid(e.target.value,'inputCidade')} id="inputCidade" placeholder="" disabled />
                      </div>
                      <div className="form-group col-md-3">
                        <div className="form-group ">
                          <label >PCC (4,5%)</label>
                          <input type="text" className="form-control" id="inputPcc" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => addNewOcorrencia(gGlobal.gProp, gGlobal.gTipo)}>Incluir</button>
                    <button type="button" className="btn btn-info " style={{ display: 'none' }} id="btnAlterar" onClick={e => alterarOcorrencia(gGlobal.gOcorrAltId)}>Alterar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button>
                  </form>




                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function pageRel(params) {
    return (
      <div id="panelRel" name="panelRel" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Lançar nova ocorrência...</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label htmlFor="inputNomeS">Tipo de Ocorrência</label>
                        <select className="form-control" id="selectTipoOcor" placeholder="Selecione abaixo o tipo de ocrrência que irá lançar" onChange={e => setTipoOcorInput()} >
                          <option value='0'>Selecione</option>
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="inputRG">Pasta</label>
                        <input type="text" className="form-control" placeholder="" id="inputPasta" disabled />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="inputCPF">Pagina</label>
                        <input type="text" className="form-control" placeholder="" id="inputPagina" disabled />
                      </div>
                      {/*                       <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >Eleito em </label>
                          <input type="date" className="form-control" id="inputDtEleito" placeholder="eleio em" />
                        </div>

                      </div> */}
                    </div>
                    <div className="form-row">

                      <div className="form-group col-md-6">
                        <label htmlFor="inputProf">Fornecedor / Prestador</label>
                        <input type="text" className="form-control" placeholder="" id="inputEmitente" disabled />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputEstadoCivil">Produto / Serviço</label>
                        <input type="text" className="form-control" placeholder="" id="inputDesc" disabled />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label >Nota Fiscal - Nº</label>
                        <input type="text" className="form-control" id="inputNf_num" placeholder="" disabled />
                      </div>
                      <div className="form-group col-md-4">
                        <div className="form-group ">
                          <label >Nota Fiscal - Data</label>
                          <input type="date" className="form-control" id="inputNf_data" placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Nota Fiscal - Valor</label>
                          <input type="text" className="form-control"  id="inputNf_valor" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Valor Pago</label>
                          <input type="text" className="form-control" id="inputVlr_pago" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group ">
                          <label >Diferença</label>
                          <input type="text" className="form-control" id="inputDif" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-9">
                        <label >Cidade</label>
                        <input type="text" className="form-control" onChange={e => validaCid(e.target.value)} id="inputCidade" placeholder="" disabled />
                      </div>
                      <div className="form-group col-md-3">
                        <div className="form-group ">
                          <label >PCC (4,5%)</label>
                          <input type="text" className="form-control" id="inputPcc" onBlur={e => CalcSimule()} placeholder="" disabled />
                        </div>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => rel1(gGlobal.gProp, gGlobal.gTipo)}>Relatoriooooo</button>
                    {/* <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button> */}
                  </form>




                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNomeS')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function geraWordOcorrencias(ncontr) {
    //const linkurl = "https://api.dreyfusvieira.com.br/docxOcorrencias.php?apikey=398738497834758934759834758934&numcontr=" + gGlobal.gContrato;
    const linkurl = "https://api.dreyfusvieira.com.br/docxOcorrencias2025v2.php?apikey=398738497834758934759834758934&numcontr=" + gGlobal.gContrato;
    window.open(linkurl, '_blank');

  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    eNnome.value = eNnome.value.toUpperCase();
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }
function alterarOcorrencia(params) {
  let let_nfe_vlr = '';
  if (document.getElementById('inputNf_valor').value != '') {
    let_nfe_vlr = realParaNumber(document.getElementById('inputNf_valor').value);
  }
  let let_nfe_vlr_pg = '';
  if (document.getElementById('inputVlr_pago').value != '') {
    let_nfe_vlr_pg = realParaNumber(document.getElementById('inputVlr_pago').value);
  }
  let let_dif = '';
  if (document.getElementById('inputDif').value != '') {
    let_dif = realParaNumber(document.getElementById('inputDif').value);
  }
  let let_pcc = '';
  if (document.getElementById('inputPcc').value != '') {
    let_pcc = realParaNumber(document.getElementById('inputPcc').value);
  }
  setAltOcorrencia(
    {
      'id': gGlobal.gOcorrAltId,
      'ocorrencia': document.getElementById('selectTipoOcor').value,
      'pasta': document.getElementById('inputPasta').value,
      'pagina': document.getElementById('inputPagina').value,
      'emitente': document.getElementById('inputEmitente').value,
      'desc': document.getElementById('inputDesc').value,
      'nfe_data': document.getElementById('inputNf_data').value,
      'nfe_num': document.getElementById('inputNf_num').value,
      'nfe_vlr': let_nfe_vlr,
      'nfe_vlr_pg': let_nfe_vlr_pg,
      'dif': let_dif,
      'cidade': document.getElementById('inputCidade').value,
      'pcc': let_pcc
    });
  let eleLoading = document.getElementById('divLoad');
  if (eleLoading) { eleLoading.style.display = ''; }
  const eActions = document.getElementById('panelActions')
  eActions.style.display = 'none';

  let iPasta = document.getElementById("inputPasta");
  iPasta.disabled = true;
  let iPagina = document.getElementById("inputPagina");
  iPagina.disabled = true;
  let iEmitente = document.getElementById("inputEmitente");
  iEmitente.disabled = true;
  let iDesc = document.getElementById("inputDesc");
  iDesc.disabled = true;
  let iNf_num = document.getElementById("inputNf_num");
  iNf_num.disabled = true;
  let iNf_data = document.getElementById("inputNf_data");
  iNf_data.disabled = true;
  let iNf_valor = document.getElementById("inputNf_valor");
  iNf_valor.disabled = true;
  let iVlr_pago = document.getElementById("inputVlr_pago");
  iVlr_pago.disabled = true;
  let iDif = document.getElementById("inputDif");
  iDif.disabled = true;
  let iCidade = document.getElementById("inputCidade");
  iCidade.disabled = true;
  let iPcc = document.getElementById("inputPcc");
  iPcc.disabled = true;

  iPasta.value = '';
  iPagina.value = '';
  iEmitente.value = '';
  iDesc.value = '';
  iNf_num.value = '';
  iNf_data.value = '';
  iNf_valor.value = '';
  iVlr_pago.value = '';
  iDif.value = '';
  iCidade.value = '';
  iPcc.value = '';
  
}
  function addNewOcorrencia(prop, tipo) {
    let let_nfe_vlr = '';
    if (document.getElementById('inputNf_valor').value != '') {
      let_nfe_vlr = realParaNumber(document.getElementById('inputNf_valor').value);
    }
    let let_nfe_vlr_pg = '';
    if (document.getElementById('inputVlr_pago').value != '') {
      let_nfe_vlr_pg = realParaNumber(document.getElementById('inputVlr_pago').value);
    }
    let let_dif = '';
    if (document.getElementById('inputDif').value != '') {
      let_dif = realParaNumber(document.getElementById('inputDif').value);
    }
    let let_pcc = '';
    if (document.getElementById('inputPcc').value != '') {
      let_pcc = realParaNumber(document.getElementById('inputPcc').value);
    }
    setAddOcorrencia(
      {
        'numcontr': gGlobal.gContrato,
        'ocorrencia': document.getElementById('selectTipoOcor').value,
        'pasta': document.getElementById('inputPasta').value,
        'pagina': document.getElementById('inputPagina').value,
        'emitente': document.getElementById('inputEmitente').value,
        'desc': document.getElementById('inputDesc').value,
        'nfe_data': document.getElementById('inputNf_data').value,
        'nfe_num': document.getElementById('inputNf_num').value,
        'nfe_vlr': let_nfe_vlr,
        'nfe_vlr_pg': let_nfe_vlr_pg,
        'dif': let_dif,
        'cidade': document.getElementById('inputCidade').value,
        'pcc': let_pcc
      });
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    const eActions = document.getElementById('panelActions')
    eActions.style.display = 'none';

    let iPasta = document.getElementById("inputPasta");
    iPasta.disabled = true;
    let iPagina = document.getElementById("inputPagina");
    iPagina.disabled = true;
    let iEmitente = document.getElementById("inputEmitente");
    iEmitente.disabled = true;
    let iDesc = document.getElementById("inputDesc");
    iDesc.disabled = true;
    let iNf_num = document.getElementById("inputNf_num");
    iNf_num.disabled = true;
    let iNf_data = document.getElementById("inputNf_data");
    iNf_data.disabled = true;
    let iNf_valor = document.getElementById("inputNf_valor");
    iNf_valor.disabled = true;
    let iVlr_pago = document.getElementById("inputVlr_pago");
    iVlr_pago.disabled = true;
    let iDif = document.getElementById("inputDif");
    iDif.disabled = true;
    let iCidade = document.getElementById("inputCidade");
    iCidade.disabled = true;
    let iPcc = document.getElementById("inputPcc");
    iPcc.disabled = true;

    iPasta.value = '';
    iPagina.value = '';
    iEmitente.value = '';
    iDesc.value = '';
    iNf_num.value = '';
    iNf_data.value = '';
    iNf_valor.value = '';
    iVlr_pago.value = '';
    iDif.value = '';
    iCidade.value = '';
    iPcc.value = '';

  }

  function AtivarCliente(cliente, numcontr) {
    let numc = cliente.substring(cliente.indexOf("º") + 1);
    gGlobal.gContrato = numc;
    let resultadoEspaco = cliente.split(" ││ ", 2);
    //cl('resultado', resultadoEspaco);
    gGlobal.gTipoOcor = resultadoEspaco[1];
    //cl('resultadook', gGlobal.gTipoOcor);
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';


    //cl('numc',numc);
    setAtu({ '0': '0' });
    let eleCardOroc = document.getElementById('cardOcorrencias');
    if (eleCardOroc) { eleCardOroc.style.display = ''; }

    let eletituloContrAtivo = document.getElementById('tituloContrAtivo');
    eletituloContrAtivo.innerHTML = cliente;
    let elepanelAtivo = document.getElementById('panelAtiva');
    elepanelAtivo.classList.add("collapsed-card");
    let eleCardpanelAtivo = document.getElementById('cardpanelAtiva');
    eleCardpanelAtivo.style.display = 'none';
    let eleIpanelAtivo = document.getElementById('ipanelAtiva');
    eleIpanelAtivo.classList.remove("fa-minus");
    eleIpanelAtivo.classList.add("fa-plus");
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let eleSelect2 = document.getElementById('selectTipoOcor');
    eleSelect2.innerHTML = '';

    let iPasta = document.getElementById("inputPasta");
    iPasta.disabled = true;
    let iPagina = document.getElementById("inputPagina");
    iPagina.disabled = true;
    let iEmitente = document.getElementById("inputEmitente");
    iEmitente.disabled = true;
    let iDesc = document.getElementById("inputDesc");
    iDesc.disabled = true;
    let iNf_num = document.getElementById("inputNf_num");
    iNf_num.disabled = true;
    let iNf_data = document.getElementById("inputNf_data");
    iNf_data.disabled = true;
    let iNf_valor = document.getElementById("inputNf_valor");
    iNf_valor.disabled = true;
    let iVlr_pago = document.getElementById("inputVlr_pago");
    iVlr_pago.disabled = true;
    let iDif = document.getElementById("inputDif");
    iDif.disabled = true;
    let iCidade = document.getElementById("inputCidade");
    iCidade.disabled = true;
    let iPcc = document.getElementById("inputPcc");
    iPcc.disabled = true;

    iPasta.value = '';
    iPagina.value = '';
    iEmitente.value = '';
    iDesc.value = '';
    iNf_num.value = '';
    iNf_data.value = '';
    iNf_valor.value = '';
    iVlr_pago.value = '';
    iDif.value = '';
    iCidade.value = '';
    iPcc.value = '';

  }

  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    document.getElementById('btnGravar').style.display = '';
    document.getElementById('btnAlterar').style.display = 'none';
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

  }

  if (repCliout.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Clientes ...</div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {pageAtivar()}
        {page1()}
        {dataTable()}
        {pageRel()}

      </div>
    )

  }


}

export default Gerador